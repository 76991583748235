<template>
  <!-- 案例展示 -->
  <div class="photo">
    <Banner v-bind="bannerProps" />
   

    <!-- 正文 -->
    <div class="photoBox">
      <!-- 案例 -->
      <ElRow>
        <ElCol v-for="item in picList" :key="item.id" :span="6">
          <RouterLink :to="{ path: '/PhotoDetail', query: { id: item.id } }">
            <ElCard :body-style="{ padding: '0px' }">
              <div class="img">
                <img :src="item.cover" class="image">
              </div>
              <div class="wenzi">
                <span>{{ item.name }}</span>
              </div>
            </ElCard>
          </RouterLink>
        </ElCol>
      </ElRow>
    </div>
  </div>
</template>

<script>
import { photo } from '@/api/home';
import Banner from '@/components/banner.vue';


export default {
  name: 'PhotoIndex',
  components: {
    Banner, 
  },
  data() {
    return {
      bannerProps: {
        backgroundImage: 'https://img.kcgeis.com/4589417273.jpg',
        cnTitle: '公司相册',
        cnTitleColor: '#018835',
        enTitle: 'COMPANY ALBUM',
        enTitleColor: '#fff',
      },
      picList: [],
      id: '',
     
    };
  },

  async created() {
    await this.getData();
  },

  methods: {
    async getData() {
      const res = await photo();
      this.picList = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.photo {
  width: 100%;
  min-height: 1000px;
  .bannerBox {
    img {
      width: 100%;
    }
    .txt {
      position: absolute;
      top: 180px;
      right: 140px;
      h2 {
        font-size: 52px;
        color: #018835;
        font-weight: bold;
        text-align: right;
      }
      h3 {
        font-size: 52px;
        font-weight: bold;
        color: #000;
        text-align: right;
      }
    }
  }
  .photoBox {
    width: 1200px;
    margin: 40px auto 0;
    .classBtnBox {
      // width: 100%;
      border: 1px #e5e5e5 solid;

      // background-color: pink;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left {
        height: 45px;
        width: 137px;
        background: #f9f9f9;
        font-size: 12px;
        color: #666;
        border-right: #e5e5e5 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .a {
        width: 100%;
        display: flex;
        margin-left: 10px;
        .b {
          margin: 0 10px;
        }
        .right {
          height: 100%;

          padding: 5px;
          font-size: 12px;
          // color: #666;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s;
          &:hover {
            border-radius: 5px;
            background-color: #018835;
            color: #fff;
            transition: 0.5s;
          }
        }
      }
    }
  }
  .el-row {
    margin-top: 30px;
    .el-card {
      margin: 0 10px 60px;
      .img {
        height: 250px;
      }
      .image {
        height: 100%;
        width: 100%;
        padding: 10px;
        object-fit: cover;
      }
      &:hover {
        background-color: #018835;
        .wenzi {
          color: #fff;
        }
      }
    }
    .wenzi {
      width: 100%;
      text-align: center;
      padding: 10px;
    }
  }
}
</style>
